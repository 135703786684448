import React, { useEffect, Fragment, useState } from "react"
import Illustration from "../../../../../assets/img/kuda-entry-illustration.inline.svg"
import PhoneBlur from "../../../../../assets/img/circle.inline.svg"
import PaymentsBlur from "../../../../../assets/img/blur/payment-blur.inline.svg"
import AccountIcon from "../../../../../assets/img/uk-payment1.inline.svg"
import ReceiveIcon from "../../../../../assets/img/uk-payment2.inline.svg"
import MoneyIcon from "../../../../../assets/img/uk-payment3.inline.svg"
import PaymentIcon from "../../../../../assets/img/uk-payment4.inline.svg"
import InstantIcon from "../../../../../assets/img/uk-payment-transfer.inline.svg"
import AnimationTextRight from "../animationTextRight"
import AnimationTextLeft from "../animationTextLeft"
import HomeHeaderFold from "../sections/homeHeaderFold"
import { scrollToElement } from "../../../../utility/utils"
import CTAUk from "../../general/ctaUk"


const entryContent = {
    title: "Send money locally and abroad easily.",
    subtitle: (
        <span>
            Make transfers within the UK, send money from the UK to Nigeria, and schedule payments with the Kuda app.
        </span>
    ),
    illustration: <Illustration />,
}

const PaymentUk = () => {
    const yourMoney = {
        title: "Move your money free of charge",
        subtitle:
            "Kuda gives you fast transfers to any UK bank account for free.",
        buttonName: "Join Kuda",
        buttonUrl: "/joinKuda/",
    }

    const phoneDebit = {
        title: (
            <span>
                Send money to Nigeria any time.
            </span>
        ),
        subtitle:
            "Make international transfers to friends and family in Nigeria at rates you can afford. More African transfer destinations are coming soon.",
        buttonName: "Join Kuda",
        buttonUrl: "/joinKuda/",
    }

    const blockCard = {
        title: "Make social payments with your Kuda Username.",
        subtitle:
            "Create a Kuda Username to send and receive money within the Kuda community.",
        buttonName: "Open your account now",
        buttonUrl: "/joinKuda/",
    }

    const saveMoney = {
        title: "Never miss another payment.",
        subtitle:
            "Schedule future transfers and recurring payments easily on the Kuda app.",
        buttonName: "Schedule Payments with Kuda",
        buttonUrl: "/joinKuda/",
    }

    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <HomeHeaderFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                image={<InstantIcon />}
                fallBack={<PaymentsBlur className="blur" />}
            />
            <AnimationTextRight
                title={yourMoney.title}
                subtitle={yourMoney.subtitle}
                fallBack={<PhoneBlur className="blur" />}
                buttonName={yourMoney.buttonName}
                buttonUrl={yourMoney.buttonUrl}
                image={<AccountIcon />}
                isWebButton={true}
            />
            <AnimationTextLeft
                title={phoneDebit.title}
                subtitle={phoneDebit.subtitle}
                buttonName={phoneDebit.buttonName}
                buttonUrl={phoneDebit.buttonUrl}
                fallBack={<PhoneBlur className="blur" />}
                image={<ReceiveIcon />}
                isWebButton={true}
            />

            <AnimationTextRight
                title={blockCard.title}
                subtitle={blockCard.subtitle}
                buttonName={blockCard.buttonName}
                buttonUrl={blockCard.buttonUrl}
                fallBack={<PhoneBlur className="blur" />}
                image={<MoneyIcon />}
            />

            <AnimationTextLeft
                title={saveMoney.title}
                subtitle={saveMoney.subtitle}
                buttonName={saveMoney.buttonName}
                buttonUrl={saveMoney.buttonUrl}
                fallBack={<PhoneBlur className="blur" />}
                image={<PaymentIcon />}
            />

            <CTAUk />
        </Fragment>
    )
}

export default PaymentUk
