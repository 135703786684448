import React from "react"
import PaymentUk from "../../components/body/pages/en-gb/payments"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PaymentPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/payments/"}
      title="Payments & Transfers | Kuda | The Money App for Africans"
      description="Send money locally and abroad easily. Make transfers within the UK, send money from the UK to Nigeria, and schedule payments with the Kuda app."
    />
    <PaymentUk />
  </Layout>
)

export default PaymentPage
